import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import Container from "react-bootstrap/Container";
import CategoriesSelector from "../../organisms/CategoriesSelector/CategoriesSelector";
import PartFinder from "../../organisms/PartFinder/PartFinder";
import { withRouter } from "react-router-dom";
import SearchResults from "../../organisms/SearchResults/SearchResults";
import CatalogueLoader from "../../molecules/CatalogueLoader/CatalogueLoader";
import NoResults from "../../molecules/NoResults/NoResults";
import UnderlinedHeader from "../../molecules/UnderlinedHeader/UnderlinedHeader";
import styled from "styled-components";
import useBasketActions from "../../../hooks/useBasketActions";
import layoutContext from "../../../context/LayoutContext";
import { useAppContext } from "../../../context/AppContext";

const createCategoryTree = (results) =>
  results.reduce(
    (prev, { categorydescription, groupdescription }) => ({
      ...prev,
      [categorydescription]: {
        ...prev[categorydescription],
        [groupdescription]:
          ((prev[categorydescription] || {})[groupdescription] || 0) + 1,
      },
    }),
    {}
  );

const removeDuplicates = (data) => {
  return data.reduce((prev, curr) => {
    const candidate = prev.find((i) => i.item.id === curr.item.id);
    if (candidate) {
      return prev;
    }
    return [...prev, curr];
  }, []);
};

const fetchParts = async (params, country) => {
  const data = {
    action: "getApplications",
    source: "pedderseu",
    manufacturergroup: "5f9bda55dcdf6b0c04f1878b",
    customer: "guest",
    currency: "eur",
    country: country,
    language: "english",
    show: true,
    make: params.make || "",
    model: params.model || "",
    year: params.year || "",
    category: params.category || "",
  };

  const response = await fetch(
    "https://ozparts2.usermd.net/api/external/search",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    throw new Error("Problem with fetching data");
  }

  const result = await response.json();

  const partsWithoutDuplicates = removeDuplicates(result.results);
  return partsWithoutDuplicates;
};

const Catalogue = (props) => {
  const context = useContext(layoutContext);
  const { userCountry } = useAppContext();
  const [categoryTree, setCategoryTree] = useState({});
  const params = new URL(document.location).searchParams;
  const { addHandler, buttonText } = useBasketActions();

  const make = params.get("make") || "";
  const model = params.get("model") || "";
  const year = params.get("year") || "";
  const category = params.get("category") || "";

  const { data: parts, isLoading } = useQuery(
    ["parts", { make, model, year, category }],
    () =>
      fetchParts({ make, model, year, category }, userCountry?.code || "PL"),
    {
      staleTime: 1000 * 60 * 120,
    }
  );

  React.useEffect(() => {
    if (parts) {
      setCategoryTree(createCategoryTree(parts));
    }
  }, [parts]);

  return (
    <Container>
      <Layout>
        <StickyWrapper>
          <CategoriesSelector categoryTree={categoryTree} />
        </StickyWrapper>
        <div id="part-finder-top">
          <UnderlinedHeader
            text={
              context.catalogueHeader || params.get("category") || "CATALOGUE"
            }
            bolder
          />
          <PartFinder />
          <CatalogueLoader show={isLoading} />
          {!isLoading && !parts.length ? (
            <NoResults />
          ) : (
            <SearchResults
              items={parts}
              addToCart={addHandler}
              buttonText={buttonText}
            />
          )}
        </div>
      </Layout>
    </Container>
  );
};

export default withRouter(Catalogue);

const Layout = styled.div`
  padding-top: 30px;
  display: grid;
  column-gap: 24px;
  grid-template-columns: 1fr;

  @media screen and (min-width: 1000px) {
    grid-template-columns: 1fr 3fr;
  }

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const StickyWrapper = styled.div`
  @media screen and (min-width: 1000px) {
    position: sticky;
    top: 20px;
    height: 80vh; 
    overflow-y: auto;
`;
