export const ribbonCategoriesOrder = [
  "SUSPENSION KITS",
  "SPRINGS AND SHOCKS",
  "SUSPENSION PARTS",
  "BUSHES",
  "STEERING",
  "BRAKES",
  "AIRBAGS",
  "OTHER",
];

export const groupDescriptionOrder = [
  "Kits",
  "Suspension Kit",
  "Suspension Kits",
  "Coil Over Kits [Full Car]",
  "Coil Over Kit [Pair] Multiple Fit",
  "Coilover Kits",
  "Centre Bearing Drop Kit",
  "Complete Strut Assembly (Front Lowered)",
  "Complete Strut Assembly (Front Standard)",
  "Complete Strut Assembly (Front Raised)",
  "Complete Strut Assembly Frt Raised +Load",
  "Complete Strut Assembly (Front)",
  "Front Spring Low",
  "Front Spring Standard",
  "Front Spring Standard Height",
  "Front Spring Raised",
  "Front Shocks",
  "Coils",
  "Front Shock Mounts",
  "Front Spring Insulator",
  "Front U Bolts",
  "Front Alignment Products",
  "Chassis Geometry Correction Kits",
  "Complete Strut Assembly (Rear)",
  "Complete Strut Assembly (Rear Raised)",
  "Rear Spring Low",
  "Rea spring standard height",
  "Rear Spring Standard",
  "Rear Spring Standard ",
  "Rear Spring Standard Height",
  "Rear Spring Raised (Variable Leaf pack)",
  "Rear Spring Raised",
  "Rear Shock",
  "Rear Shocks",
  "Rear Shock Mounts",
  "Rear Spring Insulator",
  "Spare parts (shocks)",
  "Spring Seat",
  "Leaf Spring Shackles",
  "Shackles",
  "Rear Spring All Bushes / Kits",
  "Upper Inner Bush",
  "Rear Shackle Upper Bushes",
  "Rear Shackle Lower Bushes",
  "Rear Shackle Bushes / Kits",
  "Rear Shackle Lower Bushes / Kits",
  "Rear Fixed Eye Bushes / Kits",
  "Rear Crossmember Bushes",
  "Rear Anti-roll Bar Bushes",
  "R Of R Shackle Bushes / Kit",
  "R of F Shackle Bushes / Kit",
  "Multi Use Shock Bushes",
  "Front Spring Bushes / Kits",
  "Front Shackle Bushes / Kits",
  "Front Shackle Lower Bushes",
  "Front Shackle Upper Bushes",
  "Front Fixed Eye Bushes / Kits",
  "F Of R Shackle Bushes / Kit",
  "F of F Shackle Bushes / Kit",
  "Front Leading / Trailing Arm Bushes",
  "Idler Arm &/or Bush Kit",
  "Rear Panhard Rod / Watts Link Bushes",
  "Bump Stop",
  "Dust Boot",
  "Adjusters",
  "Clevis Bracket",
  "Extended Brake Hose Sets",
  "Engine Mounts",
  "Rear U Bolts",
  "Rear U-Bolts",
  "Rear Alignment Products",
  "Rear Anti-roll Bar",
  "Rear Anti-roll Bar Links",
  "Rear Anti-roll Bars",
  "Rear Ball Joints",
  "Rear Bump Stop / Kit",
  "Rear Chassis/Body mounts",
  "Rear Hub",
  "Rear Lateral Arm Lower",
  "Rear Lateral Arm Upper",
  "Rear Miscellaneous",
  "Rear Toe Arm / Link",
  "Rear Trailing Arm Lower",
  "Rear Trailing Arm Upper",
  "Rear Undercar Protection",
  "Rear Wheel Cylinder",
  "Relay Rod",
  "Relay Rod End (Front)",
  "Relay Rod End (Rear)",
  "Tie Rod End Set",
  "Upper Ball Joint",
  "CV Shaft Assembly (Front)",
  "CV Shaft Assembly (Rear)",
  "Drag Link",
  "Inner Cv Boot (Front)",
  "Inner Cv Boot (Rear)",
  "Manual Steering Gear",
  "Outer Cv Boot (Front)",
  "Outer Cv Boot (Rear)",
  "Power Steering Gear",
  "Power Steering Hose",
  "Power Steering Pump",
  "Steering Dampers",
  "Steering Gear Couplings and  Mounts",
  "Air Tubing",
  "Airbag Controller Kits",
  "Air Bag Controllers",
  "Front Airbag",
  "High Pressure Sleeve Kits",
  "Inflation Valve",
  "Load Assist Device",
  "Steering",
  "Rear Air Bag",
  "Rear Airbag",
  "Warranty Replacement Airbag",
  "Brake Upgrade Kits",
  "Drum to Disc Conversion Kits",
  "Brake Upgrade Kit Spares",
  "Brake Upgrade Kit Components",
  "Brake Servo",
  "Front Brake Shoes",
  "Brake Pads",
  "Front Brake Pads",
  "Brake Discs",
  "Front Brake Rotors",
  "Front Brake Discs",
  "Front Brake Pad Sensor",
  "Front Brake Upgrade Kit Components",
  "Front Calipers",
  "Front Wheel Cylinder",
  "Park Brake Assembly",
  "Pedders Drum to Disc Conversion Spares",
  "Drum to Disc Conversion Spares",
  "Radius / Brake Reaction Rod or Bushes",
  "Rear Brake Drums",
  "Rear Brake Hoses",
  "Front Brake Hoses",
  "Rear Brake Pad Sensor",
  "Rear Brake Pads",
  "Rear Brake Rotors",
  "Rear Brake Shoes",
  "Rear Calipers",
  "Brake Fluid",
  "Front Anti-roll Bar",
  "Front Anti-roll Bar Bushes",
  "Front Anti-roll Bar Links",
  "Front Anti-roll Bars",
  "Front Bump Stop/Kit",
  "Front Chassis/Body mounts",
  "Control Arms",
  "Front Hub",
  "Front Lower Control Arm",
  "Front Miscellaneous",
  "Front Panhard Rod / Watts Link Bushes",
  "Front Undercar Protection",
  "Front Upper Control Arm",
  "Gas Lifter - Boot",
  "Inner Tie Rod End",
  "LoadRyder Onboard Scales",
  "Lower Ball Joint",
  "Lower Inner Bush",
  "Lowering Blocks",
  "Master Cylinder",
  "Multi Use Shock Fitting Kits",
  "Other",
  "Outer Tie Rod End",
  "Pitman Arm",
  "undefined",
];
