import React from "react";
import { withRouter } from "react-router";
import styles from "./Group.module.scss";
import styled from "styled-components";
import { PRIMARY, SECONDARY } from "../../../vars/vars";

const Group = ({ name, quantity, location, history }) => {
  const handler = (event) => {
    const group = event.target.name;
    const params = new URL(document.location).searchParams;

    if (!params.has("groups")) {
      params.set("groups", group);
    } else if (params.get("groups").includes(group)) {
      params.delete("groups");
    } else if (!params.get("groups").includes(group)) {
      params.delete("groups");
      params.set("groups", group);
    }

    setTimeout(() => {
      const element = document.getElementById("part-finder-top");
      element.scrollIntoView({
        behavior: "smooth",
      });
    }, 0);
    history.push(document.location.pathname + "?" + params.toString());
  };

  const isInUrlGroups = (name) => {
    const params = new URLSearchParams(window.location.search);
    return (
      params.has("groups") &&
      name.toLowerCase() === params.get("groups").toLowerCase()
    );
  };
  return (
    <div className={styles.Group}>
      <Row>
        <input
          checked={isInUrlGroups(name, location)}
          type="checkbox"
          name={name}
          id={name}
          onChange={handler}
        />
        <Label htmlFor={name}>
          <div>{name}</div>
          <Quantity>({quantity})</Quantity>{" "}
          {isInUrlGroups(name, location) && (
            <CheckIcon className="fa fa-check" aria-hidden="true" />
          )}
        </Label>
      </Row>
    </div>
  );
};

export default withRouter(Group);

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #444444;
  font-size: 12px;
  word-spacing: 3px;

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked + label:after {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 1px;
    left: -24px;
    z-index: 10;
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.primaryColor || "#007bff"};
  }
`;

const Label = styled.label`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-top: 10px;

  &:hover {
    color: ${PRIMARY};
    cursor: pointer;
  }

  &:before {
    position: absolute;
    top: 1px;
    left: -24px;
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    z-index: 5;
    border: 1px solid #e3e1e1;
    background-color: #f7f7f7;
    border-radius: 3px;
  }

  &:hover:after {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 1px;
    left: -24px;
    z-index: 10;
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${PRIMARY};
  }
`;

const CheckIcon = styled.i`
  position: absolute;
  left: -21px;
  top: 4px;
  z-index: 15;
  font-size: 12px;

  &hover: {
    color: ${SECONDARY};
  }
`;

const Quantity = styled.div`
  color: #7d7d7d;
  font-size: 12px;
`;
