import React from "react";
import styled from "styled-components";
import peddersLogoWebp from "../../../assets/Pedders_logo.webp";
import peddersLogo from "../../../assets/Pedders_logo.jpg";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

const Logo = () => (
  <Link to="/">
    <picture>
      <source srcSet={peddersLogoWebp} type="image/webp" />
      <Image src={peddersLogo} alt="Pedders EU logo" />
    </picture>
  </Link>
);

export default withRouter(Logo);

const Image = styled.img`
  width: 150px;
  height: 54px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 0;
  }
`;
