import React, { useEffect, useState } from "react";
import styles from "./Marquee.module.scss";
import axios from "axios";
import { useAppContext } from "./../../../context/AppContext";

function Marquee() {
  const [marqueData, setMarqueData] = useState({
    regularMessage: {
      text: "",
      isVisible: false,
    },
    errorMessage: {
      text: "",
      isVisible: false,
    },
  });

  const { initialAction } = useAppContext();
  const api_url = "https://api.ozparts.eu/marquees/pedders";

  async function fetchMarqueeData(url) {
    try {
      const res = await axios.get(url);
      const data = res.data;
      setMarqueData({
        regularMessage: {
          text: data.text_en,
          isVisible: data.show,
        },
        errorMessage: {
          text: data.error_msg_en,
          isVisible: data.show_error,
        },
      });
    } catch (error) {
      console.error("Failed to fetch marquee data:", error);
    }
  }

  const getActiveMessage = () => {
    const { errorMessage, regularMessage } = marqueData;

    if (errorMessage.isVisible) {
      return errorMessage.text;
    }

    if (regularMessage.isVisible) {
      return regularMessage.text;
    }

    return "";
  };

  useEffect(() => {
    if (initialAction) {
      fetchMarqueeData(api_url);
    }
  }, [initialAction]);

  const shouldDisplayMarquee =
    (marqueData.errorMessage.isVisible && marqueData.errorMessage.text) ||
    (marqueData.regularMessage.isVisible && marqueData.regularMessage.text);

  if (!shouldDisplayMarquee) return null;

  return (
    <div className={styles.scrollContainer}>
      <div className={styles.scrollText}>{getActiveMessage()}</div>
    </div>
  );
}

export default Marquee;
